@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@forge/common/dist/styles/colors/_index.scss';
@import '@forge/common/dist/styles/base/_index.scss';
@import '@forge/common/dist/styles/reset.css';

/*#region HoneywellColors*/
$hwColorGrey: #404040;
$hwColorBlue: #def9ff;
$hwColorWhite: #ffffff;
/*#endregion HoneywellColors*/

*::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background: #404040 !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #737373 !important;
}

*::-webkit-scrollbar {
  background: #A0A0A0;
  width:8px;
  height: 8px;
}

.highcharts-series.donut-series > path {
  stroke: transparent !important;
}

.Row {
  flex-direction: row !important;
}

.Device-Card {
  font-size: 12px;
}

.Bottom-Battery-Card {
  font-size: 12px;
  width: 45%;
  height: 90%;
  margin-left: 30%;
  margin-right: 30%;
}

.Battery-Card {
  font-size: 12px;
  width: 45%;
  height: 50%;
  margin-left: 30%;
  margin-right: 30%;
}

.Super-Container {
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - 152px);
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0.05%;
  padding-left: 0.75%;
  padding-right: 0.75%;
  background: #F0F0F0;
}

.Battery-Super-Container {
  display: inline-flex;
  height: inherit;
  width: inherit;
  justify-content: space-around;
  flex-direction: column;
  padding-top: -1%;
  padding-bottom: 2%;
}

.Sub-Container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  flex-direction: row;
}

.Table-Sub-Container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 94.5%;
}

.Less-Padding {
  padding-top: 5px;
}

.Subtitle-Style {
  margin: 0;
}

.Badge-Styler {
  margin-right: 10px;
  margin-left: 0;
}

.Card-Container {
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  height: 100%;
  width: 100%;
}

.Grid-Container {
  height: 100%;
  width: 100%;
}

.Battery-Card-Container {
  margin: 1em;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  min-width: 500px !important;
  min-height: 500px;
}

.App-Padded-Container {
  padding-left: 0.5em;
}

.Footer {
  position: sticky;
  z-index: 999;
}

.Firmware-Tab-Button {
  width: 25% !important;
}

.Search-Format {
  display: inline-flex;
  background-color: #F0F0F0;
  padding: 0%;
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.Breadcrumb-List {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 1.5em;
  list-style-type: none;
  font-family: "Honeywell Sans Web", sans-serif;
  font-size: 12px;
}

.Breadcrumb-List li {
  display: flex;
  align-items: center;
}

.Breadcrumb-List li:after{
  content: "\E90D";
  font-size: 1rem;
  color: #A0A0A0;
  font-family: "Honeywell-Global", sans-serif;
  margin-left: 5px;
  margin-right: 5px;
}

.Card-Body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Info-Button {
  cursor: pointer;
  align-self: flex-end;
}

.Card-Header {
  display: flex;
  align-items: stretch;
}

.Head-Space {
  justify-content: space-between;
}

.Batt-Stats {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25%;
}

.Firmware-Card {
  border-bottom: 1px solid #D0D0D0;
}

.Firmware-Card.ui.card {
  margin-top: 0.1em !important;
  margin-bottom: 0.1em !important;
}

.Firmware-Card.ui.card .header{
  padding-top: 0.5rem;
}
.Firmware-Card.ui.card .content{
  padding-top: 0;
  padding-bottom: 0.5rem;
}

.Firmware-Card .ui.flex-grid .row {
  padding: 0 0.5rem 0;
}

.Firmware-Card strong {
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.4em;
}

.Firmware-Card strong ~ div {
  font-weight: 300;
  font-size: 0.9em;
  line-height: 1.4em;
}

.Firmware-Col {
  margin-right: 5%;
}

.Firmware-Container {
  display: inline-flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Firmware-Button-Group {
  width: 100%;
}

.Firmware-Submit {
  flex: 1;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0.5rem !important;
}

.Firmware-Search {
  padding: 1em;
  width: 100%;
}

.Firmware-Search
.ui.search-wrap {
  width: 100%;
}

.Firmware-Search
.ui.search-wrap
.ui.search.ui-search
.ui.input
{
  width: 100%;
  min-width: 0;
}

.datepicker {
  animation: fadein 2s;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

.dashboard-select {
  display: flex;
  flex: 1;
  min-width: 368px;
  margin-right: 0.5rem;
}

.dashboard-select > .text
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.Batt-Stats-Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Classification-Row {
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 1vh;
}

.Classification-Row:hover {
  display: inline-flex;
  justify-content: space-between;
  background-color: #F0F0F0;
  padding-top: 10px;
  padding-bottom: 1vh;
}

.Link-Format {
  color: #F0F0F0;
}

.Device-Link-Format {
  color: #1792E5;
}

.Device-List {
  overflow-y: scroll;
}

.Device-List::-webkit-scrollbar {
  display: none;
}

.Link-Format:hover {
  color: #E0E0E0;
}

.Table-Container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin-top: -.5%;
}

.Super-Table-Container {
  display: inline-flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding-left: 0.75%;
}

.Table-Format {
  width: 100%;
  height: 100%;
  float: right;
  font-size: 0.5em;
}

.Col-Bye {
  width: 0;
}

.Card-Table {
  padding: 1% !important;
}

.Page-Content-Holder{
  height: 20vh;
}

.Grid-Cancel-Margin {
  margin: 0% !important;
  height: 100vh;
}

.Filter-Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25vw;
  margin-top: .50%;
  align-content: center;
  overflow-y: scroll;
  max-width: 15% !important;
}

.datatable-Container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25vw;
  margin-top: .50%;
  align-content: center;
  overflow-y: scroll;
}

.Filter-Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 70.5vh;
}

.Grid-Data {
  width: 50vw;
}

.No-Devices {
  /* width: 75vw;  */
  flex: 0.5;
  overflow: hidden;
}

.No-Devices-Message {
  margin-top: 17.5%;
}

.Card-Filters {
  background-color: transparent;
  padding: 0px 0px 0px;
  height: 100%;
}

.Card-Filters-Header {
  height: 72px;
  background-color: white;
  border: 1px solid #D0D0D0;
  border-bottom: 0;
  overflow-y: scroll;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.Card-Filters-Header .header-content {
  flex: 1;
}

.Card-Opacity {
  opacity: 0.5;
}

.Card-Filters-Content {
  overflow-y: scroll;
  height: calc(100% - 72px);
  background-color: white;
  padding: 0 !important;
}

.Filter-Card {
  overflow-y: scroll;
}

.Filter-Header-Box {
  display: flex;
  font-size: 1.1em;
  margin: 1px;
  border-bottom: 3px solid #E0E0E0;
  justify-content: space-between;
  width: inherit;
  height: inherit;
  padding: 0.8vh;
  flex-direction: row;
  flex: 1;
}

.Overflower {
  overflow-y: hidden !important;
  overflow-x: hidden;
  max-height: 100%;
}

.Overflower:hover {
  scrollbar-color: #000;
  overflow-y: scroll;
}

.Button-Margin {
  flex: 1;
  align-self: flex-end;
  word-break: keep-all;
  word-wrap: none;
}

.Pane-Overflower {
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 40vh;
}

.Pane-Overflower::-webkit-scrollbar {
  display: none;
}

.NoScroll::-webkit-scrollbar {
  display: none;
}

.Filter-Group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-left: 10px;
}

.Column-Text-Format {
  justify-content: center !important;
}

.Device-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  font-size: 1em;
  width: 50%;
  height: 100%;
  margin-left: 25%;
}

.Row-Format {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 50vh !important;
  background: #FFFFFF;
  overflow-y: scroll !important;
}

.Row-Format:hover {
  overflow-y: scroll;
  scrollbar-color: #000;
}

.Card-Error {
  height: 10vh;
  height: fit-content;
}

.Alt-Row-Format {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding-top: 1%;
}

.Last-Row-Format {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-color: #B0B0B0;
  border-bottom-width: 1px;
}

.Divider {
  width: 100%;
  border-bottom-style: solid;
  border-bottom-color: #B0B0B0;
  border-bottom-width: 1px;
}

.Device-Status-Format {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.Column-Format {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  align-content: space-between;
}

.Space {
  margin-bottom: 5vh;
}

.Less-Space {
  margin-bottom: 5vh;
}

.Device-Subcategory-Header {
  font-size: 8px !important;
}

.Device-Subcategory-Content {
  font-size: 16px !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Error-Icon {
  color: #b0b0b0;
}

.Error-Text {
  line-height: 1.5em;
}

.No-Side-Margins {
  display: flex;
  margin-left: 0px !important;
  margin-right: 0px !important;
  justify-content: space-evenly;
}

.scrollable {
  overflow-y: auto;
}

.ui-datatable thead {
  border-bottom: 1px solid #d6d6d6 !important;
}

.ui-datatable tbody {
  border-right: none !important;
  border-left: none !important;
}

.ui-datatable * {
  border: none !important;
}

.ellipsis-margin {
  margin-right: 0.3em !important;
  margin-left: 0.3em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Badge-Margin {
  margin: 0.3em;
}

@keyframes open {
  from { width: 10%; color: white; text-indent: -1000000px; height: 0%; }
  to { width: 80%; color: black; text-indent: none; height: 40%; }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-height {
  height: 80vh !important;
  overflow-y: auto !important;  
}

.modal-height-medium {
  height: 60vh !important;
}

.modal-height-small {
  height: 30vh !important;
}

.Submited input:invalid {
  border-color: #EE3124;
}

div .header {
  padding-bottom: 20px 40px;
}

.modals .Toastify {
  position: absolute;
  right: 0;
  padding-right: 1em;
}

.ui.modal .header {
  padding-bottom: 1rem;
}

.ui.modal .actions {
  padding-top: 1rem;
}

.ui.breadcrumb {
  padding: 0.75rem !important;
  padding-top: 0.5rem !important;
  margin-top: 0 !important;
}

.ui .search .input{
  width:  100%;
  border-radius: 0%;
}

.Hide {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.Show {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.No-Margins .ui.menu{
  margin: 0 !important;
}

.ui.popup.menu .ui.vertical.menu {
  min-height: 2rem;
}

.Max-Size {
  width: 100vw;
  height: 100%;
  overflow-y: hidden;
}

.Max-Size .scuf-col {
  padding-bottom: 0;
}

.divHeader {
  display: inline;
}

.pagePreview {

    background-color: #ffffff;
    margin: 10px;

  }

.pageDiv {
  position: relative;
  display: grid;
  row-gap: 0px;
  grid-row-gap: 0px;
  background-color: #ffffff;
  min-width: 216mm;
  max-width: 216mm;
  width: 216mm;
  min-height: 279mm;
  max-height: 279mm;
  height: 279mm;
  margin: .5rem auto .5rem auto;
}

.pageHeader {
  align-self: flex-start;
  display: inline-block;
  font-size: 18px;
  padding-inline-start: 5%;
  padding-inline-end: 10%;
  padding-block-start: 5%;
}

.pageFooter {
  justify-content: space-between;
  align-self: flex-end;
  /* display: inline-flex; */
  padding-inline-start: 6%;
  padding-inline-end: 6%;
  padding-block-end: 6%;

}

.pageContent {
  padding-inline-start: 5%;
  padding-inline-end: 5%;
  font-size: 12px;
}

.titleLineBig {
  font-size:1.7rem;
  font-weight: 600;
  font-family: 'Honeywell Sans Web', sans-serif;


}

.titleLineMedium {
  font-size: 23px;
  font-weight: 600;


}

.titleLineSmall {
  font-size: 22px;
  font-weight: bold;
}

.whiteTitleLine {
  font-size: 25px;
  font-weight: 600;
  color: white;
  margin-bottom:0;

}

.textSizeSubtitle {
  width:90%;
  font-size:.8rem;
  text-align:justify;
  font-weight: 300;
}

.sameLine {
  display: inline-flex;
}

.centerLabelsWithBar {
  align-items: center;
  display: flex;
  padding-inline-start: 2%;
}

.listDiv {
  display: flex;
  padding: 0.5%;
}

.paddingBlock {
  padding-block-start: 4%;
  padding-block-end: 4%;
}

.colorBarSingle {
  width:125px;
  height:25px;
}

.logoAndText {
  width: 50%;
  margin-block-start: 2%;
  margin-block-end: 2%;

  align-items: center;
}

.marginForLogoAndText {
  margin-inline-start: 3%;
}

.cornerRedImage {
  position: absolute;
  width: 30vh;
  right: 0%;
}

.colorBar {
  padding-block-start: 2%;
  padding-block-end: 3%;
  padding-inline-start: 6%;
  padding-inline-end: 6%;
  height: fit-content;
  width: 100%;
  font-size: 14px;
}

.headerColorBig {
  width: 60%;
  height: 175px;
  padding-inline-start: 6%;
  padding-inline-end: 6%;
  padding-block-start: 3%;
  padding-block-end: 3%;
}

.headerColorLittle {
  width: 40%;
  height: 175px;
  font-weight: bold;
  padding-inline-start: 6%;
  padding-inline-end: 6%;
  padding-block-start: 7%;
  padding-block-end: 3%;
}

.bodyColumnLeft {
  width: 40%;
  padding-inline-end: 4%;
  padding-block-start: 3%;
  padding-block-end: 3%;
  margin-top:-500px;
}

.bodyColumnRight {
  width: 60%;
  padding-inline-end: 3%;
  padding-block-start: 3%;
  padding-block-end: 3%;
  margin-top:-500px;
}

.ulRedClass {
  padding-inline-start: 5%;
  list-style: none;
}

.ulRedClass-no-padding {
  margin-left: -2em;
  list-style: none;
}

.liRedClass:before {
  content: "\2022";
  color: #ef2e24;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.alignTextsSpace {
  justify-content: space-between;
  align-self: flex-end;
  display: flex;

}

.tinyText {
  font-size: 14px;
}

.blueTitle {
  font-size: 17px;
  color: #1792E5;
}

.dividerContainer {
  width: 100%;
}

.pageMargins {
  padding-inline-start: 6%;
  padding-inline-end: 6%;
  padding-block-start: 3%;
  padding-block-end: 0%;
}

.splitItemsInline {
  justify-content: space-around;
}

.width75 {
  width: 75%;

}

.width25 {
  width: 25%;

}

.width60 {
  width: 70%;
  margin-right: 5%;
}

.width40 {
  width: 50%;
  margin-left: 5%;
}

.height50 {
  border-color: #E0E0E0;
  border-width: 3px;
  height: 100%;
  margin-bottom: 0%;
  border-style: solid;
}

.Warning-Icon {
  color: #b9261c;
  margin-right: 0.25em;
}

.squareDiv {
  height: 100px;
  display: flex;
  align-content:center;
  flex-direction:column;
  color: #ffffff;
  border-color: white;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  width: 20%;
  margin-block-start: 2%;
  margin-inline-end: 1%;
  padding: 2%;
  font-size: 12px;
}

.transparentSquareDiv {
  margin-inline-start: 4%;
  padding-block-start: 2%;
  padding-block-end: 0%;
  padding-inline-start: 1%;
  padding-inline-end: 1%;
  margin-block-end: 1%;
  font-size: 12px;
  color:#5b5b5f;
  line-height:17px;
}

.transparentSquareDivTitle {
  font-size: 22px;
  color:#5b5b5f;
}

.paddingDiv {
  padding-block-start: 0%;
  padding-block-end: 0%;
  padding-inline-start: 1%;
  padding-inline-end: 25%;
}

.transparentSquareDivSubtitle {
  font-size: 14px;
}

.widthLimit {
  width: fit-content;
}

.cell-data-wrap * {
  font-size: 14px;
}

.treeview {
  overflow-y: hidden;
  border-left: 1px dashed #D6D6D6;
  width: fit-content;
  text-align: left;
}

.ui.radio.checkbox {
  margin-bottom: 0;
}

.Device-Trend-Chart {
  height: 450px !important;
  width: 100% !important;
}

.chart-wrap {
  width: 100% !important;
}

.tab-pane div.ui.pointing.secondary.ui.scuf-tab.menu {
  margin-bottom: 0 !important;
  padding-left: 2em;
}

.tablePages {
  border: 1px solid rgb(155, 155, 155);
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse
}

.tablePages th,td {
  border: 1px solid rgb(155, 155, 155);
  text-align: left;
}

.scaled {
  transform: scale(0.85);
  margin-top: -20px
}

.Foo {
  width: 588px;
  height: 395px;
}

.ui.search-wrap .close-icon {
  display: none !important;
}

.ui.single-input.fluid {
  flex: 1;
}

.modal div.ui.pointing.secondary.ui.aligned.grid.menu {
  padding-left: 1.5rem !important;
}

.scuf-input-wrapper, .ui.input {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ui.selection.dropdown {
  display: flex;
  flex: 1;
  max-height: none
}

.ui.scuf-dropdown-wrapper .ui.selection.dropdown {
  max-height: none;
}

.Location-Link {
  color: #4183C4;
  font-weight: 400;
  font-size: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.Location-Font {
  font-size: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.Side-Margins {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.Left-Margin {
  margin-left: 0.5em;
}

.Right-Margin {
  margin-right: 0.5em;
}

.Input-Label-Error {
  flex-direction: column;
  flex: 1;
}

.Action-Bar .ui.scuf-dropdown-wrapper,
.Action-Bar .ui.scuf-dropdown-wrapper :last-child {
  margin-bottom: 0 !important;
  flex: 1;
}

.ui.search-wrap .ui.search.ui-search .results {
  max-height: 50vh;
  overflow-y: auto;
}

.RelativeLoader {
    position: sticky;
    display: inline-block;
}

 .cell-data-wrap .ui.fitted.checkbox {
   margin: 0;
 }

.some-map-container {
  width: 90%; height: 50%; position: inherit; outline: none;
}

.map-container {
  height: 100%;
  width: 100%;
}

.disabled {
  color: #D6D6D6;
}

.disabled:hover {
  color: #D6D6D6;
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.defaultCursor {
  cursor: default;
}

.QR-Select .ui.selection.dropdown {
  min-width: 10em !important;
  margin: 0px !important;
}

.ui.error-message {
  margin-bottom: 0;
  margin-top: 0;
}

i .Arrow {
  border: solid #B0B0B0;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.Arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.Arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.Arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.Arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.report > iframe {
  border: 0;
}

.ui-datatable-emptymessage {
  display: none;
}

.Location-Button-Group {
  width: 100%;
}

.Location-Button-Group .ui.button.inline-secondary {
  border-bottom: 0;
}

.Select {
  outline: none;
  flex: 1;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Honeywell Sans Web', sans-serif;
}

.Select-List {
  position: relative;
  margin: 0;
  padding-left: 0;
  list-style: none;
  min-width: 14em;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #1792E5;
  border-top: 0;
}

.Select-List li {
  padding-left: 0.75rem;
  min-width: 14em;
  min-height: 2.71428571em;
  max-height: 2.71428571em;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.Select-Container {
  margin-bottom: 1.5rem;
  background-color: white;
  margin-left: 1px;
  margin-right: 1px;
}

.link {
  color: #4183C4;
  text-decoration: none;
}

.Info-Pane .scuf-col.scuf-grid,
.Info-Pane .scuf-col {
  flex-direction: column;
  padding: 0;
}

.no-results .ui.search-wrap .ui.search.ui-search .results {
  display: none !important;
  background: white;
}

/* SCUF HEADER OVERRIDE */

.ui.page-header .page-header-menu.ui.menu .menu-btn-item .h-icon {
  padding: 0 !important;
}

.login-badge {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

/* SCUF GRID OVERRIDE */

.scuf-col:first-child{
  padding-left: 0px;
}

.scuf-col:last-child{
  padding-right: 0;
}

.ui.scuf-grid {
  margin-right: 0;
}

#modal-sites-select {
  margin-right: 1em;
  min-width: 368px;
  width: 20em;
  height: 3em;
}

#modal-sites-select > .text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

/************************
*************************
*****MEDIA SPECIFIC******
*************************
************************/

@media screen and (max-width: 991px) {
  .modals.dimmer .ui.scrolling.modal {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 1380px) {
  .ui.small.modal{
    width: 40% !important;
  }
}

@media screen and (max-width: 720px) {
  .ui.menu:not(.vertical) > .menu {
    overflow-x: hidden;
  }


  .foo > .ui.modal {
    padding: 0 !important;
    margin: 0 !important;
    border-left-width: '5px' !important;
    border-left-style: 'solid' !important;
    border-color: '#b9261c' !important;
  }

  .ui.page.modals.dimmer.transition.visible.active {
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    max-height: 100%;
    max-width: 100%;
  }

  .ui.page.modals.dimmer.transition.visible.active::-webkit-scrollbar {
    background: #D6D6D6;
  }

  .ui.modal {
    position: static !important;
  }

  .Error-Text {
    font-size: 1rem;
    line-height: 1.5em;
  }

  .Error-Icon {
    font-size: 40px !important;
  }

  .qr-subcontainer {
    justify-content: space-between;
  }
  .modal-height{
    height: 100% !important;
    width: 100% !important;
  }
  .ui.small.modal{
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 320px) {
  .qr-dropdown .ui.selection.dropdown {
    margin-bottom: 1rem !important;
    font-size: 14px;
  }

  .qr-dropdown div {
    padding: 0.5rem;
  }

  .qr-subcontainer {
    height: auto;
  }

  .scuf-input-wrapper .ui.input input {
    padding: 0.75rem;
    font-size: 14px;
  }

  div.ui.input-label .input-label-message {
    font-size: 14px;
  }

  .qr-img {
    width: 100%;
  }
}

@media screen and (max-height: 927px) {
  .Filter-Box {
    height: 65.35vh;
  }
}

.table-p8{
  margin: 1px;
  padding: 0px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #cccdcf;
  align-items: center;
}

.table-p8-body{
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #cccdcf;
}

.table-p7-body{
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #67737a;
}

.gray-Cell {
  background-color: #67737a;
  color: #FFFFFF;
  padding-left: 5% !important;
}

.red-Cell {
  background-color: #ef2e24;
  color: #FFFFFF;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.orange-Cell {
  background-color: #F37021;
  color: #FFFFFF;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.yellow-Cell {
  background-color: #FFC627;
  color: #000;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.green-Cell {
  background-color: #7EB338;
  color: #FFFFFF;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.blue-Cell {
  background-color: #1792E5;
  color: #FFFFFF;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.border-Cell-Bottom{
  border-bottom-style: solid;
  border-width: 1px;
  border-bottom-color: #E0E0E0;
}

.border-Cell-Top{
  border-top-style: solid;
  border-width: 1px;
  border-top-color: #E0E0E0;
}

.border-Cell-Bottom-Black{
  border-bottom-style: solid;
  border-width: 1px;
  border-bottom-color: #000;
}

.border-Cell-Top-Black{
  border-top-style: solid;
  border-width: 1px;
  border-top-color: #000;
}

.border-Cell{
  border-style: solid;
  border-width: 1px;
  border-color: #E0E0E0;
  text-align: center;
  padding-top: 0.5rem;
}

.border-Cell-Left{
  border-left-style: solid;
  border-width: 1px;
  border-left-color: #000;
}

.border-Cell-Right{
  border-right-style: solid;
  border-width: 1px;
  border-right-color: #000;
}

.light-blue-Cell {
  background-color: #D1E9FA;
  color: #000000;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.blue-Background{
  background-color: #D1E9FA;
}

.alignment-Background{
  color: black;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5%;
  padding-bottom: 3%;
  text-align: center;
}

.black-Cell {
  background-color: #000;
  color: #fff;
  height: 2rem;
  text-align: center;
  padding-top: 1%;
}

.black-Cell-p7 {
  background-color: #000;
  color: #fff;
  height: 2.5rem;
  text-align: center;
}

.medium-gray-Cell{
  background: #cccdcf;
  color: #000;
  height: 40px;
  text-align: center;
  padding-top: 1%;
}

.white-square{
  background: #FFF;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
  width: 10px;
  height: 10px;
  margin-top: 5px !important;
}

.blue-square{
  background: #1792E5;
  padding: 4px;
  width: 10px;
  height: 10px;
  margin-top: 5px !important;
}

.light-blue-square{
  background: #D1E9FA;
  padding: 4px;
  width: 10px;
  height: 10px;
  margin-top: 5px !important;
}

.red-text{
  color: #ef2e24;
}

.table-p8-cell{
  margin-top: 6px;
  margin-bottom: 6px;
  text-align: center;
}

.table-p8-info{
  padding-left: 5% !important;
  padding-top: 2%;
  padding-bottom: 2% !important;
}

.light-gray-Cell {
  background-color: #e4e4e6;
  color: #303030;
  height: 40px;
  padding-top: 1%;
  text-align: center;
}

.border-graph{
  border-style: solid;
  border-width: 1px;
}

.border-graph > p{
  color: white;
  text-align: center;
  padding-bottom: 1%;
  padding-top: 1%;
  background: #67737a;
}

.right-text{
  text-align: right;
  padding:1%;
}

.table-p7-cell{
  text-align: center;
  padding: 1%;
}

.no-padding{
  padding: 0;
}

.splitFlex {
  display: inline-flex;
}

.Fix-Padding-Grid .scuf-col {
  padding-bottom: 0;
}

.final-row{
  margin-bottom: 2% !important;
}

.no-padding-left{
  padding-left: 0% !important;
}

.no-padding-right{
  padding-right: 0% !important;
}

.date {
  text-align: right;
  margin-inline-end: 5%;
  margin-block-start: 3%;
}

.haas-config-checkbx{
  width :40px !important;
}

.recurring-services-checkbx{
  vertical-align: top !important;
}

.modal-center {
  position: static !important;
}

.dollar{
	display:inline-block;
	position: relative;
}

.dollar div .input-wrap input.input-box{
  padding-left: 15px;
}

.dollar:before {
	position: absolute;
  content:"$";
  left:5px;
  top:12px;
  z-index: 2;
}

.ui.notification.critical .icon .h-icon{
  color: #EE3124 !important;
}

.ui.notification.critical{
  border-color: #EE3124 !important;
}

.rscontainer .dollar:before {color:red;}

.ReactVirtualized__Table__sortableHeaderIcon {
  visibility: hidden;
  display: none;
}

.ActionContent .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.no-below-text .below-text {
  display: none;
}

.btFocus{
  background-color: #4183C4
}

.txtErrorColor{
  color:red !important
}

.txtSuccessColor{
  color:green !important
}

.txtDefaultColor{
  color: #1274B7 !important
}

.btnDisabledColor{
  background-color: #1274B7 !important;
  border-color: #1274B7 !important ;
}

.disableOptions{
  opacity: .5;
  z-index: 0;
  pointer-events:none;
  cursor:default;
}


.site-devices-table td:first-of-type .cell-data-wrap {
  padding: 0 0 0 1rem !important;
}

.break-word .ui.scuf-dropdown-wrapper>.ui.selection.dropdown .menu>.item {
  word-break: break-all;
}

/* Custom Icons */
@font-face {
  font-family: 'Honeywell_Custom_Icons';
  src:  url('Assets/Fonts/Honeywell_Custom_Icons.eot');
  src:  url('Assets/Fonts/Honeywell_Custom_Icons.eot') format('embedded-opentype'),
    url('Assets/Fonts/Honeywell_Custom_Icons.ttf') format('truetype'),
    url('Assets/Fonts/Honeywell_Custom_Icons.woff') format('woff'),
    url('Assets/Fonts/Honeywell_Custom_Icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'Honeywell_Custom_Icons', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-assets:before {
  content: "\e91c";
}
.icon-siteconf:before {
  content: "\e91a";
}
.icon-Scanner:before {
  content: "\e90f";
  font-family: 'Honeywell_Custom_Icons', sans-serif !important;
}
.icon-userconf:before {
  content: "\e917";
}
.icon-print:before {
  content: "\e90e";
  font-family: 'Honeywell_Custom_Icons', sans-serif !important;
}

.icon-upload:before {
  content: "\e916";
}

.icon-drag:before {
  content: "\e91d";
}

.sidebar-layout {
  overflow-x: unset !important;
 }

 .icon-robot:before {
  content: "\e91e";
  font-family: 'Honeywell_Custom_Icons', sans-serif !important;
}

.forge-notification-toast {
  right: 6rem !important;
  top: 3rem !important;
  width: 400px !important;
  p {
    display: inline-block !important;
  }
}

.forge-snack-bar {
  right: 6rem !important;
  bottom: 2rem !important;
  width: 400px !important;
  p {
    word-break: break-word !important;
    display: inline-block !important;
    pointer-events: none !important;
  }
}

.forge-snack-bar.dark {
  ._hrwLM6 {
    color: $hwColorGrey !important;
  }
  ._t5Kr5E:hover {  
  background-color: $hwColorBlue !important;
  }
}

.forge-snack-bar.light {
  ._hrwLM6 { 
    color: $hwColorWhite !important;
  }  
} 

.battery-disclaimer-tooltip-class {
  max-width: 400px;
}

.forge-modal {
  width: 400px;
  z-index: 1000 !important;
}

.forge-modal-component {
  z-index: 1000 !important;
  & .datetime-date.error .below-text{
    width: 190px;
    white-space: nowrap;
}
}

.forge-modal-component.dark {
  background-color: #404040 !important;
  color:'#FFFFFF !important';
  z-index: 1000 !important;
  ._hwUH8h{
    color: #FFFFFF !important;
  }
  & .datetime-date.error .below-text{
    width: 190px;
    white-space: nowrap;
}
}

._TS95iP {
  z-index: 999 !important;
  background-color: #000000D9 !important;
  --tw-backdrop-blur: blur(0) !important;
}

.Toastify__toast-container {
  z-index: 1001 !important;
}